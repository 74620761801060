<template>
  <div>
    <v-card v-if="!crudEnabled" key="groupsList" class="company-groups">
      <v-toolbar>
        <v-card-title>Companies</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'groups') || $can('create', 'Superadmin')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createGroup()"
          data-testid="btn-create-company"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <GroupsDialogCrud
          v-if="dialog"
          :dialog.sync="dialog"
          :editedIndex="editedIndex"
          :activeItem="activeItem"
          :signalServers="signalServers"
          @group-data-saved="fetchTableData"
          ref="refDialogGroup"
        />
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          :expanded="getUserGroupsList"
          ref="refTableCompanies"
          :calculate-widths="true"
          :loading="showTableLoader"
          :headers="headers"
          :items="getUserGroupsList"
          item-key="_key"
          class="elevation-0 border"
          v-model="selectedGroups.group_keys"
          :single-select="false"
          :show-select="selectGroupsSignalsForChange"
          :server-items-length="userGroupsCount"
          :customSearch="search"
          v-on:init-table-data="fetchTableData"
          @update-search="
            (newS) => {
              this.search = newS
            }
          "
          data-testid="table-companies"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row v-if="selectGroupsSignalsForChange" class="pa-3">
              <v-col cols="12" md="6" lg="4">
                <v-select
                  outlined
                  v-model="selectedGroups.signal_server_key"
                  :items="signalServers"
                  item-text="data.name"
                  item-value="_key"
                  :menu-props="{ maxHeight: '400' }"
                  label="Signal server"
                  hide-details
                  data-testid="filter-signal-server"
                />
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-btn
                  color="primary"
                  x-large
                  @click.stop="updateGroupsSignalServers()"
                  :disabled="selectedGroups.group_keys.length === 0"
                  data-testid="btn-update-groups-signal-servers"
                >
                  <template v-if="selectedGroups.group_keys.length > 0">
                    Update {{ selectedGroups.group_keys.length }} selected
                    groups
                  </template>
                  <template v-else>
                    <span data-testid="btn-update-groups-signal-servers">
                      Please select groups
                    </span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-3">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="4"
                v-if="$can('create', 'Superadmin')"
              >
                <v-switch
                  v-model="selectGroupsSignalsForChange"
                  label="Change signal servers"
                  input-value="true"
                  hide-details
                  data-testid="switch-change-signal-servers"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div data-testid="company-name">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.users`]="{ item }">
            <div data-testid="company-users">
              {{ item.users }}
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div data-testid="company-description">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.signal_server_key`]="{ item }">
            <div data-testid="company-signal-server">
              {{ getSignalServerName(item.signal_server_key) }}
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              @click="updateGroupStatus(item)"
              small
              :color="
                item.is_active
                  ? Pallette.statusChips.active
                  : Pallette.statusChips.notActive
              "
              text-color="white"
              data-testid="company-status"
            >
              {{ item.is_active ? "Active" : "Inactive" }}
            </v-chip>
          </template>
          <template
            v-slot:[`item.update`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <div v-if="$can('update', 'groups')">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="editGroup(item)"
                    :color="Pallette.actionButtons.edit"
                    dark
                    fab
                    x-small
                    v-on="on"
                    data-testid="btn-edit-company"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit company</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('update', 'groups') || $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editGroup(item)"
                  v-if="
                    $can('update', 'groups') || $can('update', 'Superadmin')
                  "
                  icon="mdi-pencil"
                  text="Edit company"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-company"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <v-card v-if="crudEnabled">
      <v-toolbar>
        <v-card-title>Create invite</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-stepper v-model="groupCreateWizard" vertical>
          <v-stepper-step
            :complete="groupCreateWizard > 1"
            step="1"
            data-testid="step-1-create-company"
          >
            Create company
            <small>This will be basic info about company</small>
          </v-stepper-step>
          <v-stepper-content
            step="1"
            data-testid="step-1-create-company-content"
          >
            <v-card class="mb-12">
              <v-card-text>
                <v-form
                  ref="form"
                  data-vv-scope="form-groups"
                  key="createGroupForm"
                  data-testid="form-create-group"
                >
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Company name"
                        v-model="activeItem.name"
                        :error-messages="errors.collect('form-groups.name')"
                        v-validate="'required'"
                        data-vv-name="name"
                        data-vv-validate-on="change"
                        data-testid="name"
                      />
                      <v-select
                        outlined
                        v-model="activeItem.signal_server_key"
                        :items="signalServers"
                        item-text="data.name"
                        item-value="_key"
                        :menu-props="{ maxHeight: '400' }"
                        label="Signal server"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect('form-groups.active_signal_server')
                        "
                        data-vv-name="active_signal_server"
                        data-vv-validate-on="change"
                        data-testid="signal-server"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Admin phone number"
                        v-model="activeItem.admin.phone"
                        :error-messages="
                          errors.collect('form-groups.admin_phone')
                        "
                        v-validate="{
                          regex:
                            '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                        }"
                        data-vv-name="admin_phone"
                        data-vv-validate-on="change"
                        data-vv-as="Admin phone number"
                        data-testid="admin-phone-number"
                      />
                      <v-text-field
                        outlined
                        label="Admin email"
                        v-model.trim="activeItem.admin.email"
                        :error-messages="
                          errors.collect('form-groups.admin_email')
                        "
                        v-validate="'email'"
                        data-vv-name="admin_email"
                        data-vv-validate-on="change"
                        data-testid="admin-email"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="HubSpot Key"
                        v-model="activeItem.hs_company_id"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect('form-groups.hs_company_id')
                        "
                        data-vv-name="hs_company_id"
                        data-vv-validate-on="change"
                        data-vv-as="HubSpot Key"
                        data-testid="hubSpot-key"
                      />
                      <v-switch
                        v-model="activeItem.is_active"
                        label="Active"
                        input-value="true"
                        data-vv-validate-on="change"
                        data-testid="status"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Tech support phone number"
                        v-model="activeItem.technical.phone"
                        :error-messages="
                          errors.collect('form-groups.technical_phone')
                        "
                        v-validate="{
                          regex:
                            '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                        }"
                        data-vv-name="technical_phone"
                        data-vv-validate-on="change"
                        data-testid="technical-phone"
                      />
                      <v-text-field
                        outlined
                        label="Tech support email"
                        v-model.trim="activeItem.technical.email"
                        :error-messages="
                          errors.collect('form-groups.technical_email')
                        "
                        v-validate="'email'"
                        data-vv-name="technical_email"
                        data-vv-validate-on="change"
                        data-testid="technical-email"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        label="Address"
                        v-model="activeItem.address"
                        :error-messages="errors.collect('form-groups.address')"
                        v-validate=""
                        data-vv-name="address"
                        data-vv-validate-on="change"
                        data-testid="address"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        label="Website"
                        v-model="activeItem.website"
                        :error-messages="errors.collect('form-groups.website')"
                        v-validate="{
                          regex:
                            '^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$',
                        }"
                        data-vv-name="website"
                        data-vv-validate-on="change"
                        data-testid="website"
                      />
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-textarea
                        outlined
                        label="Description"
                        v-model="activeItem.description"
                        data-vv-validate-on="change"
                        data-vv-name="description"
                        :error-messages="
                          errors.collect('form-groups.description')
                        "
                        data-testid="description"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  key="saveGroup"
                  color="primary"
                  text
                  @click="saveGroup()"
                  :disabled="!createGroupWizardStatus"
                  data-testid="btn-create-company"
                >
                  Create company
                </v-btn>
                <v-btn
                  text
                  @click="closeWizard()"
                  data-testid="btn-cancel-create-company"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step
            :complete="groupCreateWizard === 2"
            step="2"
            data-testid="step-2-create-company"
          >
            Invite admin
            <small>Invite admin or owner of this company</small>
          </v-stepper-step>
          <v-stepper-content
            step="2"
            data-testid="step-2-create-company-content"
          >
            <v-card class="mb-12">
              <v-card-text>
                <v-form
                  ref="form"
                  data-vv-scope="form-invitation"
                  key="createInviteForm"
                >
                  <v-col cols="12" md="12">
                    <!-- {{activeItem}} -->
                    <v-alert
                      v-model="createInvitesError"
                      border="left"
                      close-text="Close Alert"
                      color="deep-purple accent-4"
                      dark
                      dismissible
                      data-testid="create-invite-errors"
                    >
                      This e-mail " {{ activeItem.email }} " is already in use
                    </v-alert>
                  </v-col>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        key="inviteEmail"
                        v-model.trim="activeItem.email"
                        data-vv-name="email"
                        v-validate="'required|emailWithSpaces'"
                        label="E-mail"
                        outlined
                        :error-messages="
                          errors.collect('form-invitation.email')
                        "
                        data-testid="invite-email"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        key="inviteRole"
                        outlined
                        v-model="activeItem.role_key"
                        :items="rolesList"
                        item-text="name"
                        item-value="_key"
                        :menu-props="{ maxHeight: '400', light: false }"
                        label="Role"
                        :error-messages="
                          errors.collect('form-invitation.role_key')
                        "
                        data-vv-name="role_key"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        data-testid="invite-role"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  key="sendInvitation"
                  color="primary"
                  text
                  @click="sendInvitation()"
                  data-testid="btn-send-invite"
                >
                  Send invitation
                </v-btn>
                <v-btn
                  text
                  @click="closeWizard()"
                  data-testid="btn-cancel-create-company"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash"
import { mapGetters } from "vuex"
import GroupsDialogCrud from "./GroupsDialogCrud"
import VerticalButton from "@/components/buttons/VerticalButton"
import GeneralMixin from "@/mixins/general.js"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import SortMobile from "@/components/table/SortMobile.vue"

export default {
  components: {
    GroupsDialogCrud,
    VerticalButton,
    SortMobile,
    DataTableExtended,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      dialog: false,
      crudEnabled: false,
      createInvitesError: false,
      groupCreateWizard: 1,
      headers: [
        {
          text: "Company name",
          align: "left",
          value: "name",
          sortable: true,
          width: 220,
        },
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
        },
        {
          text: "Server",
          align: "left",
          value: "signal_server_key",
          sortable: false,
          width: 220,
        },
        {
          text: "Users",
          align: "center",
          value: "users",
          sortable: false,
          width: 120,
        },
        {
          text: "HubSpot Key",
          align: "center",
          value: "hs_company_id",
          sortable: false,
          width: 120,
        },
        {
          text: "Status",
          align: "center",
          value: "is_active",
          sortable: true,
          width: 120,
        },
        {
          text: "",
          value: "update",
          sortable: false,
          width: 40,
        },
      ],
      // group_list: [],
      selectedGroups: {
        group_keys: [],
        signal_server_key: "",
      },
      selectGroupsSignalsForChange: false,
      editedIndex: -1,
      activeItem: {},
      wizardDeafaultItem: {
        name: "",
        description: "",
        is_active: true,
        email: "",
        group_key: "",
        role_key: "",
        admin: {
          phone: "",
          email: "",
        },
        technical: {
          phone: "",
          email: "",
        },
        website: "",
        address: "",
        hs_company_id: "",
        turn_servers: [],
      },
      defaultItem: {
        name: "",
        desctiption: "",
        is_active: true,
        admin: {
          phone: "",
          email: "",
        },
        technical: {
          phone: "",
          email: "",
        },
        website: "",
        hs_company_id: "",
        turn_servers: [],
      },
      tableActions: ["update"],
      tablePermissions: {
        isPresorted: true,
        actions: ["update"],
        endpoint: "groups",
      },
      search: "",
    }
  },

  mounted: function () {
    if (!this.$can("read", "groups")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
    this.getSignalServersList()
  },

  methods: {
    fetchRolesList() {
      let fetchData = {
        params: {
          p: 1,
          pp: 500,
          keep: "_key,name,permissions",
        },
      }
      return this.$store.dispatch("getRolesList", fetchData)
    },
    fetchTableData: function () {
      let self = this
      if (self.$can("read", "groups")) {
        let getParams = self.$refs.refTableCompanies.getTableServerParams()
        let fetchData = {
          params: getParams,
        }
        if (self.search !== "") fetchData.params.search = self.search
        self.$store.dispatch("getUserGroups", fetchData)
      }
    },
    createGroup() {
      let self = this
      if (self.$can("create", "groups") || self.$can("create", "Superadmin")) {
        self.activeItem = _.cloneDeep(self.wizardDeafaultItem)
        self.editedIndex = -1
        self.$store.dispatch("defaultSignalServer").then((data) => {
          let defaultSignalKey = _.get(data, "data.data.settings_multi[0]._key")
          console.log("defaultSignalKey", defaultSignalKey, data)
          if (("defaultSignalKey", defaultSignalKey)) {
            self.activeItem.signal_server_key = defaultSignalKey
          }
          self.crudEnabled = true
        })
      }
    },
    editGroup(item) {
      let self = this
      if (self.$can("update", "groups") || self.$can("update", "Superadmin")) {
        let editItem = _.cloneDeep(item)
        self.activeItem = { ...self.defaultItem, ...editItem }
        self.editedIndex = item._key
        self.dialog = true
      }
    },
    updateGroupStatus(item) {
      let self = this
      if (self.$can("update", "groups") || self.$can("update", "Superadmin")) {
        let formData = {
          slug: item._key,
          data: {
            name: item.name,
            description: item.description,
            is_active: !item.is_active,
          },
        }
        self.$store
          .dispatch("groupUpdate", formData)
          .then((response) => {
            self.fetchTableData()
          })
          .catch((error) => {
            let params = {
              text: `Status for company ${item.name} cannot be changed`,
              show: true,
              color: "error",
            }
            self.$root.$emit("snackbar", params)
          })
      }
    },
    saveGroup() {
      let self = this
      let formData = {
        data: {
          name: self.activeItem.name,
          description: self.activeItem.description,
          is_active: self.activeItem.is_active,
          signal_server_key: self.activeItem.signal_server_key,
          admin: self.activeItem.admin,
          technical: self.activeItem.technical,
          address: self.activeItem.address,
          website: self.activeItem.website,
          hs_company_id: self.activeItem.hs_company_id,
        },
      }
      self.$validator.errors.clear("form-groups")
      self.$validator.validateAll("form-groups").then((result) => {
        if (result) {
          self.$store
            .dispatch("groupCreate", formData)
            .then((response) => {
              self
                .fetchRolesList()
                .then(() => {
                  self.activeItem.group_key = response.data.data.group._key
                  self.groupCreateWizard = 2
                })
                .catch((error) => {
                  console.log("Error on fetch roles list", error)
                })
            })
            .catch(({ response }) => {
              self.handleValidationErrors(response, "form-groups")
              //console.log('createGroup ERR', error)
            })
        }
      })
    },
    sendInvitation() {
      let self = this
      let email = self.activeItem.email
      let formData = {
        data: {
          email: email.trim(),
          group_key: self.activeItem.group_key,
          role_key: self.activeItem.role_key,
        },
      }
      self.$validator.errors.clear("form-invitation")
      self.$validator.validateAll("form-invitation").then((validated) => {
        if (validated) {
          self.$store
            .dispatch("createInvitation", formData)
            .then((response) => {
              self.closeWizard()
              self.fetchTableData()
            })
            .catch((error) => {
              self.createInvitesError = true
              setTimeout(() => {
                self.createInvitesError = false
              }, 3000)
              // console.log('createInvitation ERR', error)
            })
        }
      })
    },
    closeWizard() {
      let self = this
      self.activeItem = _.cloneDeep(self.defaultItem)
      self.crudEnabled = false
      self.groupCreateWizard = 1
    },
    getSignalServersList: function () {
      let self = this
      let getParams = {
        params: {
          type: "signal_server",
        },
      }
      self.$store.dispatch("signalServersList", getParams)
    },
    getSignalServerName(key) {
      let self = this
      let result
      if (key) {
        result = self.signalServers.filter(function (el) {
          return el._key === key
        })
        if (result.length > 0) {
          return result[0].data.name
        } else {
          return "Error"
        }
      } else {
        return "Not assigned"
      }
    },
    updateGroupsSignalServers() {
      let self = this
      let groupKeys = self.selectedGroups.group_keys.map((item) => item._key)
      let formData = {
        data: {
          group_keys: groupKeys,
          signal_server_key: self.selectedGroups.signal_server_key,
        },
      }
      self.$store
        .dispatch("updateSignalServerGroups", formData.data)
        .then(function (response) {
          self.selectGroupsSignalsForChange = false
          self.selectedGroups.group_keys = []
          self.selectedGroups.signal_server_key = ""
          self.fetchTableData()
        })
        .catch((error) => {
          console.log("updateSignalServerGroups error", error)
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      signalServersList: "signalServersList",
      getUserGroupsList: "getUserGroupsList",
      userGroupsCount: "getUserGroupsCount",
      rolesList: "rolesList",
    }),
    createGroupWizardStatus() {
      if (this.activeItem.signal_server_key && this.activeItem.name) {
        return true
      }
      return false
    },
    signalServers() {
      return _.unionBy(
        this.signalServersList,
        [{ _key: null, data: { name: "Not selected" } }],
        "_key"
      )
    },
  },
}
</script>

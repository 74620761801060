<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card elevation="10" data-testid="container-edit-company">
      <v-card-title>
        <span class="headline" v-html="getTitle()"></span>
      </v-card-title>
      <v-card-text>
        <v-form data-vv-scope="form-groups" data-testid="form-edit-company">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
                lg="3"
                v-if="!$can('update', 'Superadmin')"
              >
                <v-list dense>
                  <v-list-item
                    two-line
                    v-if="activeItem.admin && activeItem.admin.phone"
                    data-testid="admin-phone-text"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Admin phone number</v-list-item-title>
                      <v-list-item-subtitle>{{
                        activeItem.admin.phone
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    two-line
                    v-if="activeItem.admin && activeItem.admin.email"
                    data-testid="admin-email-text"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Admin email</v-list-item-title>
                      <v-list-item-subtitle>{{
                        activeItem.admin.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    two-line
                    v-if="activeItem.technical && activeItem.technical.phone"
                    data-testid="technical-phone-text"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Tech support phone number</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        activeItem.technical.phone
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    two-line
                    v-if="activeItem.technical && activeItem.technical.email"
                    data-testid="technical-email-text"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Tech support email</v-list-item-title>
                      <v-list-item-subtitle>{{
                        activeItem.technical.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                cols="12"
                :md="$can('update', 'Superadmin') ? '12' : '8'"
                :lg="$can('update', 'Superadmin') ? '12' : '9'"
              >
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-text-field
                      outlined
                      label="Company name"
                      v-model="activeItem.name"
                      :error-messages="errors.collect('form-groups.name')"
                      v-validate="'required'"
                      data-vv-name="name"
                      data-vv-validate-on="change"
                      data-testid="name"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-switch
                      v-model="activeItem.is_active"
                      label="Active"
                      input-value="true"
                      data-vv-validate-on="change"
                      data-testid="status"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      outlined
                      v-model="activeItem.signal_server_key"
                      :items="signalServers"
                      item-text="data.name"
                      item-value="_key"
                      v-validate="'required'"
                      :error-messages="
                        errors.collect('form-groups.active_signal_server')
                      "
                      data-vv-name="active_signal_server"
                      data-vv-validate-on="change"
                      :menu-props="{ maxHeight: '400' }"
                      label="Signal server"
                      :disabled="!$can('update', 'Superadmin')"
                      data-testid="signal-server"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="HubSpot Key"
                      v-model="activeItem.hs_company_id"
                      :error-messages="
                        errors.collect('form-groups.hs_company_id')
                      "
                      data-vv-name="hs_company_id"
                      data-vv-validate-on="change"
                      data-vv-as="HubSpot Key"
                      v-validate="'required'"
                      data-testid="hubspot-key"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="Address"
                      v-model="activeItem.address"
                      :error-messages="errors.collect('form-groups.address')"
                      v-validate=""
                      data-vv-name="address"
                      data-vv-validate-on="change"
                      data-testid="address"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="Website"
                      v-model="activeItem.website"
                      :error-messages="errors.collect('form-groups.website')"
                      v-validate="{
                        regex:
                          '^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$',
                      }"
                      data-vv-name="website"
                      data-vv-validate-on="change"
                      data-testid="website"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" v-if="$can('update', 'Superadmin')">
                    <v-text-field
                      outlined
                      label="Admin phone number"
                      v-model="activeItem.admin.phone"
                      :error-messages="
                        errors.collect('form-groups.admin_phone')
                      "
                      v-validate="{
                        regex:
                          '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                      }"
                      data-vv-name="admin_phone"
                      data-vv-validate-on="change"
                      data-testid="admin-phone"
                    />
                    <v-text-field
                      outlined
                      label="Admin email"
                      v-model.trim="activeItem.admin.email"
                      :error-messages="
                        errors.collect('form-groups.admin_email')
                      "
                      v-validate="'email'"
                      data-vv-name="admin_email"
                      data-vv-validate-on="change"
                      data-testid="admin-email"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" v-if="$can('update', 'Superadmin')">
                    <v-text-field
                      outlined
                      label="Tech support phone number"
                      v-model="activeItem.technical.phone"
                      :error-messages="
                        errors.collect('form-groups.technical_phone')
                      "
                      v-validate="{
                        regex:
                          '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$',
                      }"
                      data-vv-name="technical_phone"
                      data-vv-validate-on="change"
                      data-testid="technical-phone"
                    />
                    <v-text-field
                      outlined
                      label="Tech support email"
                      v-model.trim="activeItem.technical.email"
                      :error-messages="
                        errors.collect('form-groups.technical_email')
                      "
                      v-validate="'email'"
                      data-vv-name="technical_email"
                      data-vv-validate-on="change"
                      data-testid="technical-email"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" v-if="$can('update', 'Superadmin')">
                    <SelectTurnsAsync
                      v-model="activeItem.turn_servers"
                      outlined
                      label="Turn servers"
                      :multiple="true"
                      item-text="data.name"
                      item-value="_key"
                      :error-messages="
                        errors.collect(`form-groups.turn_servers`)
                      "
                      data-vv-name="backup"
                      data-vv-validate-on="change"
                      data-vv-as="turn_servers"
                      :menu-props="{ maxHeight: 304 }"
                      :prependEmptyOption="false"
                      data-testid="turn-servers"
                    />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      outlined
                      label="Description"
                      v-model="activeItem.description"
                      data-vv-validate-on="change"
                      :disabled="
                        !$can('update', 'Superadmin') ||
                        !$can('update', 'groups')
                      "
                      data-testid="description"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <v-btn color="success" text @click.stop="save()" data-testid="btn-save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectTurnsAsync from "@/components/selects/SelectTurnsAsync"
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"

export default {
  name: "groups-dialog-crud",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    signalServers: {
      type: Array,
      default() {
        return []
      },
    },
  },

  components: {
    SelectTurnsAsync,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      activeSignalServer: {
        data: {
          signal_server_key: null,
        },
      },
    }
  },

  methods: {
    getActiveSignalServer: function () {
      let self = this
      if (self.editedIndex === -1) return
      let getParams = {
        params: {
          type: "active_signal_server",
          company_key: self.activeItem.group_key,
        },
      }
      self.$store
        .dispatch("signalServersList", getParams)
        .then(function (response) {
          self.activeSignalServer = {
            ...self.activeSignalServer,
            ...(response.data.data.settings_multi[0] || []),
          }
        })
        .catch((error) => {
          //console.log('getActiveSignalServer error', error)
        })
    },
    getTitle() {
      return this.editedIndex == -1
        ? "Add new company"
        : `<span class="blue-grey--text text--lighten-4">Edit company</span> ${this.activeItem.name}`
    },
    createGroup(formData) {
      let self = this
      self.$validator.errors.clear("form-groups")
      self.$validator.validateAll("form-groups").then((result) => {
        if (result) {
          // console.log('dialog create group', formData)
          self.$store
            .dispatch("groupCreate", formData)
            .then(({ data }) => {
              self.$emit("group-data-saved")
              self.closeDialog()
            })
            .catch(({ response }) => {
              console.log("createGroup ERR", response)
              self.handleValidationErrors(response, "form-groups")
            })
        }
      })
    },
    editGroup(formData, save) {
      let self = this
      self.$validator.errors.clear("form-groups")
      let saveData = save || false
      if (saveData) {
        self.$validator.validateAll("form-groups").then((result) => {
          if (result) {
            self.$store
              .dispatch("groupUpdate", formData)
              .then(({ data }) => {
                self.$emit("group-data-saved")
                self.closeDialog()
              })
              .catch(({ response }) => {
                console.log("editGroup ERR", response)
                self.handleValidationErrors(response, "form-groups")
              })
          }
        })
      }
    },
    save() {
      let self = this
      let formData = {
        data: {
          name: self.activeItem.name,
          description: self.activeItem.description,
          is_active: self.activeItem.is_active,
          signal_server_key: self.activeItem.signal_server_key,
          admin: self.activeItem.admin,
          technical: self.activeItem.technical,
          address: self.activeItem.address,
          website: self.activeItem.website,
          hs_company_id: self.activeItem.hs_company_id,
        },
      }
      if (this.isSuperUser) {
        formData.data.turn_servers = self.activeItem.turn_servers
      }
      if (this.editedIndex > -1) {
        formData["slug"] = self.editedIndex
        self.editGroup(formData, true)
      }
      // else {
      //   self.createGroup(formData);
      // }
    },
    closeDialog() {
      this.$emit("update:dialog", false)
      this.$validator.errors.clear("form-groups")
    },
  },

  computed: {
    ...mapGetters({
      isSuperUser: "isSuperUser",
    }),
  },
}
</script>
